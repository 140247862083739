<template>
  <div class="bt-cif pa-5">
    <Header
      title="Registrasi Keluar Anggota"
      titleIcon="mdi-account-group"
      class="mb-4"
    />
    <v-container class="pa-0">
      <div class="">
        <div class="w-full white elevation-3 rounded-lg pa-3 mb-3">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.hari_transaksi"
                  v-model="form.hari_transaksi"
                  label="Hari Transaksi"
                  required
                  :rules="[(v) => !!v || 'Hari Transaksi is required']"
                />
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.cm"
                  v-model="form.cm_code"
                  label="Rembug"
                  required
                  :rules="[(v) => !!v || 'Rembug is required']"
                />
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.cif"
                  v-model="form.cif_no"
                  label="ID Anggota"
                  required
                  :rules="[(v) => !!v || 'ID Anggota is required']"
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.nama"
                  label="Nama Lengkap (Sesuai KTP)"
                  readonly
                  required
                  :rules="[
                    (v) => !!v || 'Nama Lengkap (Sesuai KTP) is required',
                  ]"
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_pokok"
                  label="Saldo Pokok"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_margin"
                  label="Saldo Margin"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_simsus"
                  label="Saldo Simsus"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.sihata_mingguan"
                  label="Sihata Mingguan"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_simwa"
                  label="Saldo Simwa"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_sukarela"
                  label="Saldo Sukarela"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_lainnya"
                  label="Saldo Tabungan Lainnya"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.simpanan_pokok"
                  label="Saldo Simpanan Pokok"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_sda"
                  label="Saldo SDA"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_hutang"
                  label="Saldo Hutang"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.saldo_simpanan"
                  label="Saldo Simpanan"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.setoran_tambahan"
                  label="Setoran Tambahan"
                  disabled
                />
                <v-text-field
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.penarikan_tabungan_sukarela"
                  label="Penarikan Sukarela"
                  disabled
                />
                <v-text-field
                  type="date"
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.tanggal_mutasi"
                  label="Tanggal Keluar"
                  required
                  :rules="[(v) => !!v || 'Tanggal Keluar is required']"
                />
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.reason"
                  v-model="form.alasan"
                  label="Alasan"
                  required
                  :rules="[(v) => !!v || 'Alasan is required']"
                />
                <v-textarea
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.keterangan"
                  label="Keterangan"
                  required
                  :rules="[(v) => !!v || 'Keterangan is required']"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>

      <v-row>
        <v-col cols="6" class="pb-0">
          <router-link to="/keanggotaan/dashboard">
            <v-btn block class="purple lighten-1 white--text">Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn
            block
            class="purple lighten-1 white--text"
            @click="doSave"
            :disabled="!isFormValid"
          >
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";

export default {
  name: "KeanggotaanRegistrasiMasuk",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
      },
      form: {
        hari_transaksi: null,
        cm_code: null,
        cif_no: null,
        nama: null,
        saldo_pokok: 0,
        saldo_margin: 0,
        saldo_simsus: 0,
        sihata_mingguan: 0,
        saldo_simwa: 0,
        saldo_sukarela: 0,
        saldo_lainnya: 0,
        simpanan_pokok: 0,
        saldo_sda: 0,
        saldo_hutang: 0,
        saldo_simpanan: 0,
        setoran_tambahan: 0,
        penarikan_tabungan_sukarela: 0,
        tanggal_mutasi: null,
        alasan: null,
        keterangan: null,
      },
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.cm_code": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.getInfoMember();
        }
      },
      immediate: true,
    },
    "form.cif_no": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          const option = this.list.cif.find((item) => item.value === newValue);
          this.form.nama = option ? option.text : "";
          this.getInfoSaldo();
        }
      },
      immediate: true,
    },
    overlay: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    async doSave() {
      if (!this.$refs.form.validate()) return;

      this.overlay = true;
      let payload = new FormData();
      payload.append("cif_no", this.form.cif_no);
      payload.append("tanggal_mutasi", this.form.tanggal_mutasi);
      payload.append("alasan", this.form.alasan);
      payload.append("keterangan", this.form.keterangan);
      payload.append("fa_code", this.user.fa_code);
      payload.append("saldo_pokok", this.form.saldo_pokok);
      payload.append("saldo_margin", this.form.saldo_margin);
      payload.append("saldo_simsus", this.form.saldo_simsus);
      payload.append("account_cash_code", this.user.account_cash_code);
      payload.append("saldo_simwa", this.form.saldo_simwa);
      payload.append("saldo_sukarela", this.form.saldo_sukarela);
      payload.append("saldo_lainnya", this.form.saldo_lainnya);
      payload.append("simpanan_pokok", this.form.simpanan_pokok);
      payload.append("saldo_sda", this.form.saldo_sda);
      payload.append("setoran_tambahan", this.form.setoran_tambahan);
      payload.append(
        "penarikan_tabungan_sukarela",
        this.form.penarikan_tabungan_sukarela
      );
      try {
        const req = await services.transactionRegisAnggotaKeluar(
          payload,
          this.user.token
        );
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };

          setTimeout(() => {
            this.$router.push(`/keanggotaan/dashboard`);
          }, 1000);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoRembug() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },

    async getInfoMember() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cm_code", this.form.cm_code);
      try {
        const req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          const cif =
            req.data.data.map((item) => {
              return {
                value: item.cif_no,
                text: item.nama,
              };
            }) || [];
          this.list.cif = cif;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoSaldo() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cif_no", this.form.cif_no);
      try {
        const req = await services.infoSaldo(payload, this.user.token);
        if (req.status === 200) {
          const { profile } = req.data.data;
          Object.assign(this.form, {
            saldo_pokok: profile.saldo_pokok || 0,
            saldo_margin: profile.saldo_margin || 0,
            saldo_simsus: profile.saldo_simsus || 0,
            sihata_mingguan: profile.sihata_mingguan || 0,
            saldo_simwa: profile.saldo_simwa || 0,
            saldo_sukarela: profile.saldo_sukarela || 0,
            saldo_lainnya: profile.saldo_lainnya || 0,
            simpanan_pokok: profile.simpanan_pokok || 0,
            saldo_sda: profile.saldo_sda || 0,
            saldo_hutang: profile.saldo_hutang || 0,
            saldo_simpanan: profile.saldo_simpanan || 0,
            setoran_tambahan: profile.setoran_tambahan || 0,
            penarikan_tabungan_sukarela:
              profile.penarikan_tabungan_sukarela || 0,
          });
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },

    async getInfoReason() {
      this.overlay = true;
      try {
        const req = await services.infoReason("", this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const reason =
              req.data.data.map((item) => {
                return {
                  value: item.reason_code,
                  text: item.reason_name,
                };
              }) || [];
            this.list.reason = reason;
          } else {
            this.alert = {
              show: true,
              msg: "Reason tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
  },
  mounted() {
    this.getInfoReason();
  },
};
</script>
